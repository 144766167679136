import axios from "axios";

const createUserVersion = async (user) => {
  const res = await axios.post("/UserVersions", user);
  return res.data;
};

const updateUserVersion = async (user) => {
  const res = await axios.put("/UserVersions", user);
  return res.data;
};

const approveUserVersion = async (id) => {
  const res = await axios.patch(
    `/UserVersions/approveUserVersion?userVersionId=${id}`,
  );
  return res.data;
};

const declineUserVersion = async (id) => {
  const res = await axios.patch(
    `/UserVersions/declineUserVersion?userVersionId=${id}`,
  );
  return res.data;
};

const getUserVersionsByUserId = async (userId) => {
  const res = await axios.get(`/UserVersions/user/${userId}`);
  return res.data;
};

const userVersionsApi = {
  createUserVersion,
  approveUserVersion,
  declineUserVersion,
  getUserVersionsByUserId,
  updateUserVersion,
};

export default userVersionsApi;
