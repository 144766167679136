import axios from "axios";

const getTags = async () => {
  const res = await axios.get("/Tags");
  return res.data;
};

const tagsApi = {
  getTags,
};

export default tagsApi;
