export const passwordRegex =
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=(.*[0-9]){2,})([!_|@#$%^&*-])?(?=.{8,32}))|(?=.*?[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯӘәҒғҚқҢңӨөҰұҮүҺһІі])(?=(.*[0-9]){2,})(?=.*?[!_|@#$%^&*-])?(?=.{8,32})/;

const itemsCount = 20;
export const postsCount = itemsCount;
export const newsCount = itemsCount;
export const materialsCount = itemsCount;
export const healthTopicsCount = itemsCount;

export const errorsMessages = {
  "Please, confirm email.": "pleaseConfirmEmail",
  "User was not found.": "userNotFound",
  "Email was not sent.": "emailWasNotSent",
  "Incorrect password.": "IncorrectPasswordOrPassword",
  "User was deactivated.": "userWasDeactivated",
};

export const postAction = {
  create: "create",
  edit: "edit",
  createWithLinked: "createWithLinked",
};

const bytesInMb = 1000000;
export const maxSize = { file: 10 * bytesInMb, video: 20 * bytesInMb };

export const notifyOption = {
  success: "success",
  error: "error",
};

export const order = {
  asc: "asc",
  decs: "desc",
};

export const orderBy = {
  date: "date",
};

export const fileFormat = {
  csv: "csv",
  xlsx: "xlsx",
};

export const modalOptions = [
  "root",
  {
    preventScroll: true,
    closeOnOverlayClick: true,
  },
];

export const reactionsTypes = ["like", "love", "haha", "wow", "sad"];

export const getCurrentError = (e) =>
  e.response?.data?.message || e.message || "An unknown error occurred";
