import axios from "axios";

const getArticles = async (count, page, language) => {
  let url = `/Articles/getArticles?amountOfEntities=${count}&page=${page}`;
  if (language) {
    url += `&language=${language}`;
  }
  const res = await axios.get(url);
  return res.data;
};

const getArticleById = async (id) => {
  const res = await axios.get(`/Articles/${id}`);
  return res.data;
};

const deleteArticleById = async (id) => {
  const res = await axios.delete(`/Articles/${id}`);
  return res;
};

const updateArticle = async (article) => {
  const res = await axios.put("/Articles", article);
  return res.data;
};

const addArticle = async (article) => {
  const res = await axios.post("/Articles", article);
  return res.data;
};

const editArticle = async (article) => {
  const res = await axios.put("/Articles", article);
  return res.data;
};

const articlesApi = {
  getArticles,
  getArticleById,
  deleteArticleById,
  updateArticle,
  addArticle,
  editArticle,
};

export default articlesApi;
