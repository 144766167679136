const routes = {
  home: "/",
  news: "/news",
  certainNews: "/news/:id",
  aboutUs: "/about-us",
  socialize: "/socialize",
  postById: "/socialize/:id",
  healthTopics: "health-topics",
  materialsAndInstructions: "/materials-and-instructions",
  SHEsurvey: "/assessment-tool",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  confirmEmail: "/confirm-email",
  userProfile: "/user-profile/:id",
  notFound: "*",
  changePassword: "/change-password",
  adminUsers: "/admin/users",
  adminSchoolUsers: "/admin/users/:id",
  moderatorSchoolUsers: "/moderator/users/:id",
  adminModeration: "/admin/moderate",
  specificModeration: "/moderate/:id",
  adminSchools: "/admin/schools",
  reports: "/reports",
  specificReports: "/reports/:id",
  adminAddSchool: "/admin/schools/addSchool",
  moderatorUsers: "/moderator/users",
  moderatorModeration: "/moderator/moderate",
  moderatorSchools: "/moderator/schools",
  myPosts: "/myPosts",
  assessmentToolStatistics: "/assessment-tool/statistics",
  assessmentToolStatisticsBySchool: "/assessment-tool/statistics/:id",
};

export default routes;
