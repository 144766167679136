import axios from "axios";

const getSchoolsFiltered = async (amount, page, filter) => {
  const res = await axios.post(
    `/Schools/AllFiltered?amount=${amount}&page=${page}`,
    filter,
  );
  return res.data;
};

const getSchoolById = async (id) => {
  const res = await axios.get(`/Schools/${id}`);
  return res.data;
};

const getAllActiveSchoolsByDistrictId = async (id) => {
  const res = await axios.get(`/Schools/getAllActiveSchoolsByDistrictId/${id}`);
  return res.data;
};

const getSchoolsForModeration = async (amount, page, filter) => {
  const res = await axios.post(
    `/Schools/schoolsForModeration?amount=${amount}&page=${page}`,
    filter,
  );
  return res.data;
};

const addSchool = async (school) => {
  const res = await axios.post("/Schools", school);
  return res.data;
};

const updateSchool = async (school) => {
  const res = await axios.put(`/Schools`, school);
  return res.data;
};

const deactivateSchool = async (id) => {
  const res = await axios.patch(`/Schools/DeactivateSchool/${id}`);
  return res.data;
};

const activateSchool = async (id) => {
  const res = await axios.patch(`/Schools/ActivateSchool/${id}`);
  return res.data;
};

const getActiveSchoolsAmount = async () => {
  const res = await axios.get(`/Schools/activeSchoolsAmount`);
  return res.data;
};

const schoolsApi = {
  getSchoolsFiltered,
  getSchoolById,
  getAllActiveSchoolsByDistrictId,
  getSchoolsForModeration,
  addSchool,
  updateSchool,
  deactivateSchool,
  activateSchool,
  getActiveSchoolsAmount,
};

export default schoolsApi;
