import { ReactComponent as About } from "assets/images/header/navBar/aboutIcon.svg";
import { ReactComponent as Book } from "assets/images/header/navBar/bookIcon.svg";
import { ReactComponent as Health } from "assets/images/header/navBar/healthIcon.svg";
import { ReactComponent as Home } from "assets/images/header/navBar/homeIcon.svg";
import { ReactComponent as LogIn } from "assets/images/header/navBar/loginIcon.svg";
import { ReactComponent as News } from "assets/images/header/navBar/newsIcon.svg";
import { ReactComponent as Note } from "assets/images/header/navBar/notebookIcon.svg";
import { ReactComponent as Socialize } from "assets/images/header/navBar/socializeIcon.svg";
import defaultAvatar from "assets/images/userProfile/defaultAvatar.png";
import routes from "config/routes";
import { useMainContext } from "context";
import { getLocalFile } from "helpers/storageFiles";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { NavLink } from "react-router-dom";

import s from "./NavBar.module.css";

const NavLinks = ({ close, isLoggedIn, user }) => {
  const { id, avatarFileName, firstName } = user || {};
  const { t } = useTranslation();
  const { userLoading } = useMainContext();
  const clickHandler = () => close(false);

  return (
    <nav className={s.nav}>
      <ul className={s.list}>
        <li onClick={clickHandler}>
          <NavLink to={routes.home} end className={s.navLink}>
            <Home className={s.svg} />
            {t("Header.navBar.home")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.aboutUs} className={s.navLink}>
            <About className={s.svg} />
            {t("Header.navBar.about")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.socialize} className={s.navLink}>
            <Socialize className={s.svg} />
            {t("Header.navBar.socialize")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.news} end className={s.navLink}>
            <News className={s.svg} />
            {t("Header.navBar.news")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.healthTopics} className={s.navLink}>
            <Health className={s.svg} />
            {t("Header.navBar.health")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.materialsAndInstructions} className={s.navLink}>
            <Book className={s.svg} />
            {t("Header.navBar.materials")}
          </NavLink>
        </li>
        <li onClick={clickHandler}>
          <NavLink to={routes.SHEsurvey} className={s.navLink}>
            <Note className={s.svg} />
            {t("Header.navBar.assessment")}
          </NavLink>
        </li>
      </ul>
      {userLoading && (
        <Loader
          className={s.userLoader}
          color="#FFAB17"
          type="Circles"
          width="20px"
          height="20px"
        />
      )}
      {isLoggedIn && !userLoading && (
        <NavLink
          to={`/user-profile/${id}`}
          className={s.isLoggedIn}
          onClick={clickHandler}
        >
          <img
            src={avatarFileName ? getLocalFile(avatarFileName) : defaultAvatar}
            alt="Post img"
            className={s.avatar}
          />
          <p>{firstName}</p>
        </NavLink>
      )}
      {!userLoading && !isLoggedIn && (
        <NavLink to={routes.login} className={s.login} onClick={clickHandler}>
          <LogIn className={s.loginSvg} />
          {t("Header.navBar.login")}
        </NavLink>
      )}
    </nav>
  );
};

export default NavLinks;
