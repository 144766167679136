import axios from "axios";

const getData = async (id) => {
  const res = await axios.get(`/StorageService/${id}`);
  return res.data;
};

const addData = async (file) => {
  const res = await axios.post("/StorageService", file);
  return res.data;
};

const insertListOfFiles = async (files) => {
  const res = await axios.post("/StorageService/insertListOfFiles", files);
  return res.data;
};

const deleteDataById = async (id) => {
  const res = await axios.delete(`/StorageService/${id}`);
  return res;
};

const storageService = {
  addData,
  insertListOfFiles,
  deleteDataById,
  getData,
};

export default storageService;
