import { getCurrentError } from "config/constants";
import { useMainContext } from "context";
import { useLocalStorage } from "helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { usersApi } from "services";

const SessionEvens = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useLocalStorage("token", "");
  const { logout, user } = useMainContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      fetchTokenUser();
    }
  }, [token]);

  const fetchTokenUser = async () => {
    try {
      setLoading(true);
      const tokenUser = await usersApi.getTokenUser(token);
      if (tokenUser.role !== user.role) {
        toast.warn(t("General.roleChanged"));
        logout();
      }
    } catch (e) {
      if (e.response.status === 401) {
        toast.warn(t("General.loginAsk"));
        return setToken("");
      }
      toast.error(getCurrentError(e));
    } finally {
      setLoading(false);
    }
  };

  return <>{!loading && children}</>;
};
export default SessionEvens;
