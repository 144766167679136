import Container from "components/Container";

import NavLinks from "./NavLinks";

import s from "./NavBar.module.css";

const Navigation = ({ setIsOpen, isLoggedIn, user }) => (
  <div className={s.navigation}>
    <Container>
      <NavLinks close={setIsOpen} isLoggedIn={isLoggedIn} user={user} />
    </Container>
  </div>
);

export default Navigation;
