import axios from "axios";

const getMaterials = async (count, page, language) => {
  let url = `/Materials/getMaterials?amountOfEntities=${count}&page=${page}`;
  if (language) {
    url += `&language=${language}`;
  }
  const res = await axios.get(url);
  return res.data;
};

const getMaterialById = async (id) => {
  const res = await axios.get(`/Materials/${id}`);
  return res.data;
};

const deleteMaterialById = async (id) => {
  const res = await axios.delete(`/Materials/${id}`);
  return res;
};

const updateMaterial = async (material) => {
  const res = await axios.put("/Materials", material);
  return res.data;
};

const addMaterial = async (material) => {
  const res = await axios.post("/Materials", material);
  return res.data;
};

const materialsApi = {
  getMaterials,
  getMaterialById,
  deleteMaterialById,
  updateMaterial,
  addMaterial,
};

export default materialsApi;
