import Container from "components/Container";

import NavLinks from "./NavLinks";

import s from "./NavBar.module.css";

const MobileNavigation = ({ setIsOpen, isLoggedIn, user }) => {
  return (
    <div className={s.mobileNavigation}>
      <Container>
        <NavLinks close={setIsOpen} isLoggedIn={isLoggedIn} user={user} />
      </Container>
    </div>
  );
};

export default MobileNavigation;
