export const statusEnum = {
  Approved: 0,
  Declined: 1,
  Pending: 2,
};

const getPostStatus = (status) => {
  switch (status) {
    case 0:
      return "Approved";
    case 1:
      return "Declined";
    case 2:
      return "Pending";
    default:
      return "Pending";
  }
};

export default getPostStatus;

export const getPostColor = (status) => {
  switch (status) {
    case 0:
      return "#31ad1f";
    case 1:
      return "#c71c33";
    case 2:
      return "#FFC122";
    default:
      return "#FFC122";
  }
};
