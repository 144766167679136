import axios from "axios";

const getCommentsByPostId = async (id) => {
  const res = await axios.get(`/Comments/getCommentsByPostId/${id}`);
  return res.data;
};

const deleteCommentById = async (id) => {
  const res = await axios.delete(`/Comments/${id}`);
  return res;
};

const updateCommentById = async (id, comment) => {
  const res = await axios.put(`/Comments/${id}`, comment);
  return res.data;
};

const createComment = async (comment) => {
  const res = await axios.post("/Comments", comment);
  return res.data;
};

const addLike = async (like) => {
  const res = await axios.post("/Comments/like", like);
  return res.data;
};

const deleteLike = async (id) => {
  const res = await axios.delete(`/Comments/like?dislikingElementId=${id}`);
  return res;
};

const commentsApi = {
  getCommentsByPostId,
  deleteCommentById,
  updateCommentById,
  createComment,
  addLike,
  deleteLike,
};

export default commentsApi;
