import Footer from "components/Footer";
import Header from "components/Header";
import routes from "config/routes";
import React, { lazy, Suspense } from "react";
import Loader from "react-loader-spinner";
import { Route, Routes } from "react-router-dom";

import "./App.css";

const ButtonToTopWrapper = lazy(() => import("components/ButtonToTopWrapper"));
const Home = lazy(() => import("pages/Home"));
const AboutUs = lazy(() => import("pages/AboutUs"));
const Socialize = lazy(() => import("pages/Socialize"));
const Health = lazy(() => import("pages/Health"));
const Materials = lazy(() => import("pages/Materials"));
const Survey = lazy(() => import("pages/Survey"));
const Login = lazy(() => import("pages/Login"));
const News = lazy(() => import("pages/News"));
const Register = lazy(() => import("pages/Register"));
const ConfirmEmail = lazy(() => import("pages/Confirm"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const Article = lazy(() => import("pages/CertainNews"));
const UserProfile = lazy(() => import("pages/UserProfile"));
const NotFound = lazy(() => import("pages/NotFound"));
const PostById = lazy(() => import("pages/PostById"));
const AdminModeration = lazy(() => import("pages/AdminModeration"));
const AdminSchools = lazy(() => import("pages/AdminSchools"));
const ModeratorPosts = lazy(() => import("pages/ModeratorPosts"));
const ModeratorSchools = lazy(() => import("pages/ModeratorSchools"));
const AddSchool = lazy(() => import("pages/AddSchool"));
const AllSchoolModeration = lazy(() => import("pages/AllSchoolModeration"));
const AssessmentToolStatistics = lazy(() =>
  import("pages/AssessmentToolStatistics"),
);
const AssessmentToolStatisticsBySchool = lazy(() =>
  import("pages/AssessmentToolStatisticsBySchool"),
);
const UsersTable = lazy(() => import("pages/UsersTable"));

function App() {
  return (
    <div className="mainContainer">
      <Header />
      <Suspense
        fallback={
          <div className="lazyLoaderWrapper">
            <Loader type="Bars" color="#ff8614" height={200} width={200} />
          </div>
        }
      >
        <Routes>
          <Route
            path={routes.home}
            exact
            element={
              <ButtonToTopWrapper>
                <Home />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.news}
            exact
            element={
              <ButtonToTopWrapper>
                <News />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.certainNews}
            element={
              <ButtonToTopWrapper>
                <Article />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.aboutUs}
            exact
            element={
              <ButtonToTopWrapper>
                <AboutUs />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.socialize}
            exact
            element={
              <ButtonToTopWrapper>
                <Socialize />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.postById}
            element={
              <ButtonToTopWrapper>
                <PostById />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.healthTopics}
            exact
            element={
              <ButtonToTopWrapper>
                <Health />
              </ButtonToTopWrapper>
            }
          />
          <Route
            exact
            path={routes.materialsAndInstructions}
            element={
              <ButtonToTopWrapper>
                <Materials />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.SHEsurvey}
            exact
            element={
              <ButtonToTopWrapper>
                <Survey />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.login}
            exact
            element={
              <ButtonToTopWrapper>
                <Login />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.register}
            exact
            element={
              <ButtonToTopWrapper>
                <Register />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.userProfile}
            exact
            element={
              <ButtonToTopWrapper>
                <UserProfile />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.confirmEmail}
            exact
            element={
              <ButtonToTopWrapper>
                <ConfirmEmail />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.forgotPassword}
            exact
            element={
              <ButtonToTopWrapper>
                <ForgotPassword />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.resetPassword}
            exact
            element={
              <ButtonToTopWrapper>
                <ResetPassword />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.changePassword}
            exact
            element={
              <ButtonToTopWrapper>
                <ChangePassword />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.adminUsers}
            exact
            element={
              <ButtonToTopWrapper>
                <UsersTable />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.adminModeration}
            exact
            element={
              <ButtonToTopWrapper>
                <AdminModeration />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.specificModeration}
            exact
            element={
              <ButtonToTopWrapper>
                <AllSchoolModeration />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.adminSchools}
            exact
            element={
              <ButtonToTopWrapper>
                <AdminSchools />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.moderatorUsers}
            exactS
            element={
              <ButtonToTopWrapper>
                <UsersTable />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.moderatorSchoolUsers}
            exactS
            element={
              <ButtonToTopWrapper>
                <UsersTable />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.adminSchoolUsers}
            exactS
            element={
              <ButtonToTopWrapper>
                <UsersTable />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.moderatorModeration}
            exact
            element={
              <ButtonToTopWrapper>
                <ModeratorPosts />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.moderatorSchools}
            exact
            element={
              <ButtonToTopWrapper>
                <ModeratorSchools />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.adminAddSchool}
            exact
            element={
              <ButtonToTopWrapper>
                <AddSchool />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.assessmentToolStatistics}
            exact
            element={
              <ButtonToTopWrapper>
                <AssessmentToolStatistics />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.assessmentToolStatisticsBySchool}
            exact
            element={
              <ButtonToTopWrapper>
                <AssessmentToolStatisticsBySchool />
              </ButtonToTopWrapper>
            }
          />
          <Route
            path={routes.notFound}
            element={
              <ButtonToTopWrapper>
                <NotFound />
              </ButtonToTopWrapper>
            }
          />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
