import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import MobileNavigation from "./MobileNavigation";
import Navigation from "./Navigation";

const NavBar = ({ setIsOpen, open, isLoggedIn, user }) => {
  const mobileNavigationRef = useRef(null);

  return (
    <>
      <Navigation setIsOpen={setIsOpen} isLoggedIn={isLoggedIn} user={user} />
      <CSSTransition
        in={open}
        nodeRef={mobileNavigationRef}
        classNames="mobileNavAnimation"
        timeout={100}
        unmountOnExit
      >
        <div ref={mobileNavigationRef}>
          <MobileNavigation
            setIsOpen={setIsOpen}
            isLoggedIn={isLoggedIn}
            user={user}
          />
        </div>
      </CSSTransition>
    </>
  );
};

export default NavBar;
