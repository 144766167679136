import axios from "axios";

const getRegions = async () => {
  const res = await axios.get("/Regions");
  return res.data;
};

const getAllRegionsWithDistricts = async () => {
  const res = await axios.get("/Regions/getAllRegionsWithDistricts");
  return res.data;
};

const getAllRegionsWithAmountOfSchools = async () => {
  const res = await axios.get("/Regions/getAllRegionsWithAmountOfSchools");
  return res.data;
};

const regionsApi = {
  getRegions,
  getAllRegionsWithDistricts,
  getAllRegionsWithAmountOfSchools,
};

export default regionsApi;
