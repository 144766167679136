import { ReactComponent as CloseIcon } from "assets/images/header/closes.svg";
import { ReactComponent as BurgerIcon } from "assets/images/header/dots.svg";
import { ReactComponent as HeaderLogo } from "assets/images/header/logo.svg";
import Locales from "components/Header/Locales";
import routes from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import s from "./Logo.module.css";

const Logo = ({ isOpen, open }) => {
  const { t } = useTranslation();
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <Link to={routes.home} className={s.logoWrapper}>
          <HeaderLogo className={s.logo} />
          <h2 className={s.header}>{t("General.websiteName")}</h2>
        </Link>
      </div>
      <div className={s.localesContainer}>
        <Locales />
      </div>
      <button className={s.button} onClick={isOpen}>
        {open ? <CloseIcon /> : <BurgerIcon />}
      </button>
    </div>
  );
};

export default Logo;
