import cn from "classnames";
import { storageLanguage } from "helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { languages } from "../../../i18next";

import style from "./Locales.module.css";

const Locales = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(storageLanguage());

  const onChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return languages.map((e) => (
    <button
      key={e}
      onClick={() => onChangeLang(e)}
      className={cn(style.localesBtn, {
        [style.activeLang]: language === e,
      })}
    >
      {e}
    </button>
  ));
};

export default Locales;
