export const RoleNames = {
  Admin: "Admin",
  Moderator: "Moderator",
  User: "User",
};

export default [
  {
    role: RoleNames.Admin,
    id: 0,
  },
  {
    role: RoleNames.Moderator,
    id: 1,
  },
  {
    role: RoleNames.User,
    id: 2,
  },
];
