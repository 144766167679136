import axios from "axios";
import { sortingFilter } from "helpers/sortingFilter";

const getPosts = async (
  amount,
  page,
  tags,
  schoolsFilter,
  sortFilter = sortingFilter.Newest,
) => {
  const res = await axios.post(`/Posts/getAll`, schoolsFilter, {
    params: {
      amountOfPosts: amount,
      page: page,
      tagsFilter: tags,
      sortingFilter: sortFilter,
    },
  });
  return res.data;
};

const createPost = async (files) => {
  const res = await axios.post("/Posts", files);
  return res.data;
};

const getPostById = async (id) => {
  const res = await axios.get(`/Posts/${id}`);
  return res.data;
};

const deletePost = async (id) => {
  const res = await axios.delete(`/Posts/${id}`);
  return res;
};

const getAllPostsBySchoolIdForModeration = async (
  id,
  amount,
  page,
  moderationType,
) => {
  let requestString = `/Posts/pending/${id}?amountOfPosts=${amount}&page=${page}`;
  if (moderationType >= 0) {
    requestString += `&type=${moderationType}`;
  }

  const res = await axios.get(requestString);
  return res.data;
};

const createPostVersion = async (post) => {
  const res = await axios.post("/PostVersions", post);
  return res.data;
};

const approvePostVersion = async (id) => {
  const res = await axios.patch(
    `/PostVersions/approvePostVersion?id=${id}`,
    id,
  );
  return res.data;
};

const declinePostVersion = async (id) => {
  const res = await axios.patch(`/PostVersions/declinePostVersion?id=${id}`);
  return res.data;
};

const getMostLikedPost = async () => {
  const res = await axios.get(
    "/Posts/getMostLikedPostDuringLastMonth?amount=1",
  );
  return res.data;
};

const deletePostLike = async (id) => {
  const res = await axios.delete(`/Posts/like?dislikingElementId=${id}`);
  return res.data;
};

const addPostLike = async (like) => {
  const res = await axios.post("/Posts/like", like);
  return res.data;
};

const updatePostLike = async (like) => {
  const res = await axios.put("/Posts/like", like);
  return res.data;
};

const getAllUserPostsByUserId = async (amount, page, id) => {
  const res = await axios.get(
    `/Posts/getAllUserPostsByUserId/${id}?amountOfPosts=${amount}&page=${page}`,
  );
  return res.data;
};

const updateMedia = async (formData) => {
  const res = await axios.post("/posts/updateMedia", formData);
  return res.data;
};

const postsApi = {
  getPosts,
  createPostVersion,
  getPostById,
  deletePost,
  getAllPostsBySchoolIdForModeration,
  getAllUserPostsByUserId,
  createPost,
  getMostLikedPost,
  deletePostLike,
  addPostLike,
  approvePostVersion,
  declinePostVersion,
  updateMedia,
  updatePostLike,
};

export default postsApi;
