import axios from "axios";

const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = "";
  },
};

const getUsers = async () => {
  const res = await axios.get("/Users");
  return res.data;
};

const getFilteredUsers = async (filter, amount, page) => {
  const res = await axios.post(
    `/Users/getAllFiltered?amount=${amount}&page=${page}`,
    filter,
  );
  return res.data;
};

const getUserById = async (id) => {
  const res = await axios.get(`/Users/${id}`);
  return res.data;
};

const updateUserById = async (user, id) => {
  const res = await axios.patch(`/Users/${id}`, user);
  return res.data;
};

const deactivateUserForAdmin = async (id) => {
  const res = await axios.patch(`/Users/deactivateUserForAdmin/${id}`);
  return res.data;
};

const deactivateUser = async () => {
  const res = await axios.patch("/Users/deactivateUser");
  return res.data;
};

const activateUser = async (id) => {
  const res = await axios.patch(`/Users/activateUser/${id}`);
  return res.data;
};

const addUser = async (user) => {
  const res = await axios.post("/Users/register", user);
  return res.data;
};

const confirmUser = async (user) => {
  const res = await axios.post("/Users/confirmEmail", user);
  return res.data;
};

const changePassword = async (password) => {
  const res = await axios.post("/Users/changePassword", password);
  return res.data;
};

const loginUser = async (user) => {
  const res = await axios.post("/Users/login", user);
  token.set(res.data.token);
  return res.data;
};

const forgotPassword = async (email) => {
  const res = await axios.post("/Users/forgotPassword", email);
  return res.data;
};

const resetPassword = async (user) => {
  const res = await axios.post("/Users/resetPassword", user);
  return res.data;
};

const changeUserRole = async (role) => {
  const res = await axios.patch("/Users/setUserRole", role);
  return res.data;
};

const getAllActiveUsersBySchoolId = async (id) => {
  const res = await axios.get(`/Users/getAllActiveUsersBySchoolId/${id}`);
  return res.data;
};

const getAllUsersBySchoolId = async (id, amount, page, filter) => {
  const res = await axios.post(
    `/Users/getAllUsersBySchoolId/${id}?amount=${amount}&page=${page}`,
    filter,
  );
  return res.data;
};

const getCurrentUser = async (persistToken) => {
  token.set(persistToken);
  const res = await axios.get("/Users/getCurrentUser");
  return res.data;
};

const getTokenUser = async (persistToken) => {
  token.set(persistToken);
  const res = await axios.get("/Users/getTokenUser");
  return res.data;
};

const getCurrentUserProfile = async () => {
  const res = await axios.get("/Users/getCurrentUserProfile");
  return res.data;
};

const logoutUser = () => {
  localStorage.removeItem("token");
  return token.unset("");
};

const updateAvatar = async (file) => {
  const res = await axios.post("/Users/updateAvatar", file);
  return res.data;
};

const updateBanner = async (file) => {
  const res = await axios.post("/Users/updateBackground", file);
  return res.data;
};

const getAllUsersPending = async (schoolId) => {
  const res = await axios.get(`/UserVersions/pending/${schoolId}`);
  return res.data;
};

const approveUserVersion = async (userVersionId) => {
  const res = await axios.patch(
    `/UserVersions/approveUserVersion?userVersionId=${userVersionId}`,
  );
  return res.data;
};

const declineUserVersion = async (userVersionId) => {
  const res = await axios.patch(
    `/UserVersions/declineUserVersion?userVersionId=${userVersionId}`,
  );
  return res.data;
};

const usersApi = {
  getUsers,
  getFilteredUsers,
  getUserById,
  addUser,
  loginUser,
  updateUserById,
  deactivateUserForAdmin,
  deactivateUser,
  activateUser,
  confirmUser,
  changePassword,
  forgotPassword,
  resetPassword,
  changeUserRole,
  getAllActiveUsersBySchoolId,
  getAllUsersBySchoolId,
  getCurrentUser,
  getTokenUser,
  getCurrentUserProfile,
  logoutUser,
  updateAvatar,
  updateBanner,
  getAllUsersPending,
  approveUserVersion,
  declineUserVersion,
};

export default usersApi;
