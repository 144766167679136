import { ReactComponent as Email } from "assets/images/footer/email.svg";
import { ReactComponent as Facebook } from "assets/images/footer/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/footer/instagram.svg";
import { ReactComponent as LinkSvg } from "assets/images/footer/link.svg";
import { ReactComponent as Location } from "assets/images/footer/location.svg";
import { ReactComponent as Phone } from "assets/images/footer/phone.svg";
import Container from "components/Container";
import routes from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import s from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className={s.footerInner}>
        <div className={s.footerSocialSection}>
          <Container>
            <ul className={s.socialList}>
              <li className={s.socialItem}>
                <a
                  href="https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2FWHOKazakhstan%2Fabout%2F%3Fref%3Dpage_internal"
                  className={s.socLink}
                  target="blank"
                >
                  <Facebook className={s.icon} />
                  Facebook
                </a>
              </li>
              <li className={s.socialItem}>
                <a
                  href="https://www.instagram.com/whokazakhstan/"
                  className={s.socLink}
                  target="blank"
                >
                  <Instagram className={s.icon} />
                  Instagram
                </a>
              </li>
            </ul>
          </Container>
        </div>
        <div className={s.footerContentSection}>
          <Container>
            <ul className={s.list}>
              <li className={s.itemNav}>
                <div className={s.iconWrapper}>
                  <Link to={routes.home} className={s.link}>
                    {t("Header.navBar.home")}
                  </Link>
                </div>

                <div className={s.iconWrapper}>
                  <Link to={routes.aboutUs} className={s.link}>
                    {t("Header.navBar.about")}
                  </Link>
                </div>
                <div className={s.iconWrapper}>
                  <Link to={routes.socialize} className={s.link}>
                    {t("Header.navBar.socialize")}
                  </Link>
                </div>
                <div className={s.iconWrapper}>
                  <Link to={routes.news} className={s.link}>
                    {t("Header.navBar.news")}
                  </Link>
                </div>
                <div className={s.iconWrapper}>
                  <Link to={routes.healthTopics} className={s.link}>
                    {t("Header.navBar.health")}
                  </Link>
                </div>
                <div className={s.iconWrapper}>
                  <Link to={routes.materialsAndInstructions} className={s.link}>
                    {t("Header.navBar.materials")}
                  </Link>
                </div>
                <div className={s.iconWrapper}>
                  <Link to={routes.SHEsurvey} className={s.link}>
                    {t("Header.navBar.assessment")}
                  </Link>
                </div>
              </li>

              <li className={s.item}>
                <h3 className={s.header}>{t("Footer.health.title")}</h3>
                <div className={s.iconLocationWrapper}>
                  <Location className={s.icon} />
                  <a
                    className={s.link}
                    href="https://goo.gl/maps/6os8oma36RPEZYaFA"
                    target="blank"
                  >
                    {t("Footer.health.location1")}
                    <p>{t("Footer.health.location2")}</p>
                  </a>
                </div>
                <div className={s.iconWrapper}>
                  <Phone className={s.icon} />
                  <a className={s.link} href="tel: 87172954105">
                    8 (7172) 954105
                  </a>
                </div>
                <div className={s.iconWrapper}>
                  <Email className={s.icon} />
                  <a
                    className={s.link}
                    href="mailto: secretariat@hls.kz"
                    target="blank"
                  >
                    secretariat@hls.kz
                  </a>
                </div>
              </li>
              <li className={s.item}>
                <h3 className={s.header}>{t("Footer.who.title")}</h3>
                <div className={s.iconLocationWrapper}>
                  <Location className={s.icon} />
                  <a
                    className={s.link}
                    href="https://goo.gl/maps/sijHTCdyb9d2mL1f6"
                    target="blank"
                  >
                    {t("Footer.who.location1")}
                    <p>{t("Footer.who.location2")}</p>
                    <p>{t("Footer.who.location3")}</p>
                  </a>
                </div>
                <div className={s.iconWrapper}>
                  <Phone className={s.icon} />
                  <a className={s.link} href="tel: 77084251553">
                    +7 708 425 1553
                  </a>
                </div>

                <div className={s.iconWrapper}>
                  <Phone className={s.icon} />
                  <a className={s.link} href="tel: 77084251552">
                    +7 708 425 1552
                  </a>
                </div>
                <div className={s.iconWrapper}>
                  <Email className={s.icon} />
                  <a
                    className={s.link}
                    href="mailto: eurowhokaz@who.int"
                    target="blank"
                  >
                    eurowhokaz@who.int
                  </a>
                </div>
                <div className={s.iconWrapper}>
                  <LinkSvg className={s.icon} />
                  <a
                    className={s.link}
                    href="https://www.who.int/"
                    target="blank"
                  >
                    www.who.int
                  </a>
                </div>
              </li>
            </ul>
          </Container>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
