import axios from "axios";

const createSimpleRepost = async (id) => {
  const res = await axios.patch(`/Reposts/simple?Id=${id}`);
  return res.data;
};

const createRepostWithPost = async (post) => {
  const res = await axios.post("/Reposts/extended", post);
  return res.data;
};

const deleteRepost = async (id) => {
  const res = await axios.delete(`/Reposts/${id}`);
  return res.data;
};

const getAllUserRepostsByUserId = async (amount, page, id) => {
  const res = await axios.get(
    `/Reposts/getAllUserRepostsByUserId/${id}?amountOfPosts=${amount}&page=${page}`,
  );
  return res.data;
};

const repostsApi = {
  createSimpleRepost,
  createRepostWithPost,
  getAllUserRepostsByUserId,
  deleteRepost,
};

export default repostsApi;
