import Container from "components/Container";
import { useMainContext } from "context";
import React, { useState } from "react";

import Logo from "./Logo";
import NavBar from "./NavBar";

import s from "./Header.module.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn, user } = useMainContext();

  const openHandler = () => setIsOpen((prev) => !prev);

  return (
    <header>
      <div className={s.logoWrapper}>
        <Container>
          <Logo isOpen={openHandler} open={isOpen} />
        </Container>
      </div>
      <div className={s.navBarWrapper}>
        <NavBar
          setIsOpen={openHandler}
          open={isOpen}
          isLoggedIn={isLoggedIn}
          user={user}
        />
      </div>
    </header>
  );
};

export default Header;
