import "config/axiosConfig";
import { MainContextProvider } from "context";
import "modern-normalize/modern-normalize.css";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionEvens from "utilities/SessionEvents";

import App from "./App";
import "./i18next";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <MainContextProvider>
      <SessionEvens>
        <Suspense
          fallback={
            <div className="loaderWrapper">
              <Loader type="Bars" color="#ff8614" height={200} width={200} />
            </div>
          }
        >
          <App />
          <ToastContainer position="top-center" />
        </Suspense>
      </SessionEvens>
    </MainContextProvider>
  </BrowserRouter>,
);
