import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import storageLanguage from "./storageLanguage";

const useTimePassed = (startTime) => {
  const timePassedInMinutes =
    (moment.utc() - moment.utc(startTime)) / (1000 * 60);
  const { t } = useTranslation();

  return useCallback(() => {
    const lang = storageLanguage();
    const isRu = lang === "ru";
    const isEn = lang === "en";
    const days = Math.floor(timePassedInMinutes / (60 * 24));
    const mins = Math.floor(timePassedInMinutes % (1000 * 60 * 60));
    const hours = Math.floor(
      (timePassedInMinutes % (1000 * 60 * 60 * 24)) / 60,
    );

    const pluralize = (prefix, value) =>
      (value > 1 ? `${prefix}s` : prefix) + " ago";

    let daysPrefix = t("Time.daysAgo");
    if (isRu && days === 1) {
      daysPrefix = "день назад";
    }
    if (isRu && days > 1 && days < 5) {
      daysPrefix = "дня назад";
    }

    if (isEn) {
      daysPrefix = pluralize("day", days);
    }

    let minPrefix = t("Time.MinutesAgo");
    if (isRu && mins === 1) {
      minPrefix = "минуту назад";
    }
    if (isRu && mins > 1 && mins < 5) {
      minPrefix = "минуты назад";
    }

    if (isEn) {
      minPrefix = pluralize("minute", mins);
    }

    let hoursPrefix = t("Time.hoursAgo");
    if (isRu && hours === 1) {
      hoursPrefix = "час назад";
    }
    if (isRu && hours > 1 && hours < 5) {
      hoursPrefix = "часа назад";
    }
    if (isEn) {
      hoursPrefix = pluralize("hour", hours);
    }

    if (days !== 0) {
      if (days > 30) {
        return moment(startTime).format("DD.MM.YYYY");
      }
      return `${days} ${daysPrefix}`;
    }
    if (hours !== 0) {
      return `${hours} ${hoursPrefix}`;
    }
    if (days === 0 && mins === 0) {
      return t("Time.justNow");
    }
    return `${mins} ${minPrefix}`;
  }, [timePassedInMinutes]);
};

export default useTimePassed;
