import axios from "axios";

const getHealthTopics = async (count, page, language, criterion) => {
  let url = `/HealthTopics/getHealthTopics?amountOfEntities=${count}&page=${page}&healthTopicCriterionId=${criterion}`;
  if (language) {
    url += `&language=${language}`;
  }
  const res = await axios.get(url);
  return res.data;
};

const getHealthTopicById = async (id) => {
  const res = await axios.get(`/HealthTopics/${id}`);
  return res.data;
};

const deleteHealthTopicById = async (id) => {
  const res = await axios.delete(`/HealthTopics/${id}`);
  return res;
};

const updateHealthTopic = async (healthTopic) => {
  const res = await axios.put("/HealthTopics", healthTopic);
  return res.data;
};

const addHealthTopic = async (healthTopic) => {
  const res = await axios.post("/HealthTopics", healthTopic);
  return res.data;
};

const materialsApi = {
  getHealthTopics,
  getHealthTopicById,
  deleteHealthTopicById,
  updateHealthTopic,
  addHealthTopic,
};

export default materialsApi;
