import { getCurrentError } from "config/constants";
import routes from "config/routes";
import { getUserRole, useLocalStorage } from "helpers";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usersApi } from "services";

const MainContext = React.createContext({
  user: {},
  setUser: () => {},
  userRole: 2,
  setUserRole: () => {},
});

export const MainContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState("");
  const [token, setToken] = useLocalStorage("token", "");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentUser(token);
  }, [token]);

  useEffect(() => {
    if (user) {
      setUserRole(getUserRole(user.role));
    }
  }, [user]);

  const getCurrentUser = async (token) => {
    try {
      setUserLoading(true);
      if (token) {
        const response = await usersApi.getCurrentUser(token);
        setUser(response);
        setIsLoggedIn(true);
      }
    } catch (e) {
      if (e.response.status === 401) {
        toast.warn(t("General.loginAsk"));
        return logout();
      }
      toast.error(getCurrentError(e));
    } finally {
      setUserLoading(false);
    }
  };

  const getToken = (res) => {
    setUser(res);
    setToken(res.token);
    setIsLoggedIn(true);
  };

  const updateCurrentUser = () => getCurrentUser(token);

  const logout = () => {
    usersApi.logoutUser();
    setIsLoggedIn(false);
    setUserRole("");
    setUser(null);
    navigate(routes.login);
  };

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        userRole,
        setUserRole,
        isLoggedIn,
        userLoading,
        getToken,
        logout,
        updateCurrentUser,
      }}
    >
      {!userLoading && children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);
